import React, {useEffect, useState} from 'react'
import FirstLayer from './FirstLayer'
import Experience from './Experience'
import { useViewPort } from './useViewPort'
import MobileExperience from './MobileExperience'



export default function App() {
    const [isContact, setIsContact] = useState(false)
    const {width} = useViewPort()
    const breakPoint = 600
    
    

    const handleContact = () => {        
        if (!isContact) {
            window.dataLayer.push({'event': 'contact'})
        }
        setIsContact(!isContact) 
          

    }
    const handleHome = () => {
        setIsContact(false)
    }
    
    return (
        <>
            <FirstLayer handleContact={handleContact} isContact={isContact} handleHome={handleHome}/>     
            {
                width > breakPoint ?
                    <Experience handleContact={handleContact} isContact={isContact}/>
                    :
                    <MobileExperience handleContact={handleContact} isContact={isContact}/>
            }       
            
        </>
    )
}
