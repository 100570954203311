import React from 'react'
import Header from './Header'

export default function Home() {
    return (
        <div className="home-container">
            <div></div>
            <div className="bio">
                <div>Namasté, I’m Lorenzo. I’m unreasonably excited about art, technology and will become your best friend if you talk with me about Tai chi.</div>
                
                {/* <div>and will become your best friend if you talk with me about Tai chi.</div> */}
            </div>
            <div className="model-home">Model</div>
            <div className="socials">
                <ul className='sidebar'>
                        <li>
                            <a href='https://github.com/lorenzoajt' target={'_blank'}>github</a>
                            
                        </li>
                        <li>
                            <a href='https://www.linkedin.com/in/lorenzoajt/' target={'_blank'}>linkedIn</a>
                        </li>
                </ul>
            </div>
            <div className="footer">
                <a onClick={() =>{window.dataLayer.push({'event': 'work-click'})}} href='https://speckled-ship-063.notion.site/Lorenzo-Works-0d482abb02624a639500b63bb96eb284' target={'_blank'}>work.</a>
            </div>
        </div>
    )
}
