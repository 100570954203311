import React, { Suspense } from 'react';
import { Html, ContactShadows, PresentationControls, Float, Environment, useGLTF, CameraControls } from '@react-three/drei'
import { suspend } from 'suspend-react'
const Character = React.lazy(() => import('./Character'));


import TriggerSound from './TriggerSound'
import { Canvas } from '@react-three/fiber'
import { Vector3 } from 'three';
import { useEffect, useRef } from 'react';

export default function Experience({isContact})
{    
    const cactus = useGLTF('/cactus.gltf')      

    const positionA = [-3, 1.5, 4 ]
    const targetA = [0, 0, 0]
    const positionB = [-3, 1.5, 4]
    const targetB = [2, -0.5, 1]
    let t = 1
    const cameraControlsRef = useRef()
        
    
    
    
    useEffect(() => {
        if(isContact){
            cameraControlsRef.current?.lerpLookAt(
                positionA[0], positionA[1], positionA[2], 
                targetA[0], targetA[1], targetA[2], 
                positionB[0], positionB[1], positionB[2], 
                targetB[0], targetB[1], targetB[2], 
                t,
                true)

        }
        else{
            cameraControlsRef.current?.lerpLookAt(
                positionB[0], positionB[1], positionB[2], 
                targetB[0], targetB[1], targetB[2], 
                positionA[0], positionA[1], positionA[2], 
                targetA[0], targetA[1], targetA[2], 
                t,
                true)

        }            
      
    }, [isContact])
    
    
    
    return (    
        
        <Canvas
                camera={ {
                    fov: 45,
                    near: 0.1,
                    far: 2000,
                    position: positionA,                             
                } }
        >
            
            <>
                
                {/* <Html position={[0, 0, 1]} >
                    <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/7kjhE45fqnQgQ0EgSGgRJz?utm_source=generator&theme=0" frameBorder= {0} height={"80px"} ></iframe>                    
                </Html >                     
                <Html>
                    <TriggerSound />
                </Html>                 */}
                <Environment files={'./environmentMaps/city.hdr'} />
                <color args={ ['#121212'] } attach="background"/>
                
                <PresentationControls 
                    global            
                    config={{ mass: 2, tension: 400}}
                    snap={{ mass: 4, tension: 400}}
                >
                    <Float rotationIntensity={0.2}>      
                                                                        
                        <rectAreaLight
                            width={ 2.5 }
                            height={ 1.65 }
                            intensity={ 65 }
                            color={ '#a6fd29' }
                            rotation={ [ - 0.1, Math.PI, 0 ] }
                            position={ [ 0, 0.55, - 1.15 ] }
                        />
                        
                        <Suspense>

                            <Character isContact={isContact}/>                                   
                        </Suspense>
                        
                        
                        
                        
                        
                        <primitive
                            object={ cactus.scene}
                            scale={0.2}
                            position={[1 , -1.2, 1]}                         
                        />                                             
                    </Float>            
                    

                </PresentationControls>
                <CameraControls 
                    ref={cameraControlsRef}
                />
                <ContactShadows 
                    position-y={-1.4}
                    opacity={0.4}
                    scale={5}
                    blur={2.4}
                />
                
                
            </>

        </Canvas>
    )
}