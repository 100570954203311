import React, { useEffect } from 'react'
import Header from './Header'

export default function Contact({handleContact}) {

    return (
        <div className="contact-container">
            {/* <Header handleContact={handleContact}/> */}
            <div></div>
            <div className="model"></div>
            <div className="content">
                <div className="row1">
                    <div>
                        <div className="bigText">let's dance together</div>
                        <div className="smallTextDesktop">or maybe just some tea and chit chat</div>
                        <div className="mobileText">Don't be shy!</div>
                    </div>
                    
                </div>
                <div className="row2">
                    <div>
                        <div className="smallText">email:</div>
                        <div className='links'>lorenzoajt@gmail.com</div>
                    </div>
                    
                </div>
                <div className="row3">
                    <div>
                        <div className="smallText">follow me here:</div>
                        <ul className="contact-socials">
                            <li className='links'>
                                <a href='https://github.com/lorenzoajt' target={'_blank'}>github</a>
                            </li>
                            <li className='links'>
                                <a href='https://www.linkedin.com/in/lorenzoajt/' target={'_blank'}>linkedIn</a>
                            </li>                            
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div className="footer">
                <a onClick={window.dataLayer.push({'event': 'work-click'})} href='https://speckled-ship-063.notion.site/Lorenzo-Works-0d482abb02624a639500b63bb96eb284' target={'_blank'}>work.</a>
            </div>
        </div>
    )

}
