import React from 'react'
import Home from './Home'
import Contact from './Contact'
import Header from './Header'

export default function FirstLayer({handleContact, isContact, handleHome}) {
    

    return (
        <>  
            <Header handleContact={handleContact} isContact={isContact} handleHome={handleHome}/>
            {   
                isContact ? 
                <Contact />     
                :
                <Home />   
                
            }
        </>
        
    )
}
