import React from 'react'

export default function Header({handleContact, isContact, handleHome}) {
    
    return (
        <div className="header">
            <div className='logo'>
                <button onClick={handleHome}>
                    {'>'}_LT
                </button>
                
            </div>
            <div className='contactButton'>
                {
                    isContact ?
                    <button className='closeButton' onClick={handleContact}>
                        x
                    </button>
                    :
                    <button onClick={handleContact}>
                        contact.
                    </button>
                }
                
                
            </div>
        </div>
    )

}