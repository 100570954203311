import './style.css'
import ReactDOM from 'react-dom/client'
import App from './App'
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'

ReactGA.initialize("G-Q8NTMM5LGR");

const tagManagerArgs = {
    gtmId: 'GTM-K6KXPZB'
}
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.querySelector('#root'))

// const [isContact, setIsContact] = useState(false)



root.render(
    <>        
        <App/>
                            
    </>
    
    
)