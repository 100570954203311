import { useAnimations, useGLTF } from '@react-three/drei'
import { useEffect, useRef } from 'react'
import { useControls, button} from 'leva'
import { useFrame } from '@react-three/fiber'
import { Vector3 } from 'three'

export default function MobileCharacter ({isContact}) {

    const character = useGLTF('./model.gltf')
    const { actions } = useAnimations(character.animations, character.scene)
    
    const markerRef = useRef()
    
        
    
    useEffect(() => {
        let action = actions["Waving"]
        if(isContact){
            action = actions["Cumbia"]
            action
                .reset()
                .fadeIn()
                .play()

        }else{
            action = actions["Waving"]            
            action
                .reset()
                .fadeIn()
                .play()
        }
        return () => {
            action.fadeOut(0.5)
        }
        
        
        
    }, [isContact])
    
    return (
        
        <primitive 
            object= { character.scene}
            position-y= {-1.2}
            ref={markerRef}
            scale={0.8}
            
        />
        
        
    )
    
}
